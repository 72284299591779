<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div
        class="sidebar-shop"
        :class="{'show': mqShallShowLeftSidebar}"
      >
        <b-row>
          <b-col cols="12">
            <h6 class="filter-heading d-none d-lg-block mt-5px">
              Filtros de búsqueda
            </h6>
          </b-col>
        </b-row>

        <!-- Filters' Card -->
        <b-card>

          <!-- Multi Range -->
          <div class="multi-range-price">
            <h6 class="filter-title mt-0">
              Rango de precios
            </h6>
            <br><br>
            <vue-slider
              v-model="filters.priceRange"
              :min="0"
              :max="17000"
              :enable-cross="false"
              :tooltip="'always'"
              :tooltip-formatter="priceFormatter"
              class="p-1"
            />

            <b-button
              size="sm"
              class="mr-1 btn-gradient-promo"
              @click="filterAction"
            >
              Filtrar
            </b-button>
            <b-button
              size="sm"
              variant="primary"
              class="btn-gradient-promo"
              @click="cleanFilters"
            >
              Limpiar filtros
            </b-button>
          </div>

          <!-- todo: aquí hay que agregar los filtros faltantes, no tiene caso que yo los ponga xD-->

          <!-- Categories -->
          <div class="product-categories">
            <h6 class="filter-title">
              Categorías
            </h6>
            <b-form-radio-group
              v-model="filters.categories"
              class="categories-radio-group"
              stacked
              :options="filterOptions.categories"
            />

            <h6 class="filter-title">
              Marcas
            </h6>
            <b-form-radio-group
              v-model="filters.brands"
              class="categories-radio-group"
              stacked
              :options="filterOptions.brands"
            />
            <br>
            <b-button
              size="sm"
              class="mr-1 btn-gradient-promo"
              @click="filterAction"
            >
              Filtrar
            </b-button>
            <b-button
              variant="primary"
              class="btn-gradient-promo"
              @click="cleanFilters"
            >
              Limpiar filtros
            </b-button>
          </div>
        </b-card>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormRadioGroup, BButton,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
  components: {
    BRow,
    BCol,
    BFormRadioGroup,
    BCard,
    BButton,
    VueSlider,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      priceFormatter: v => `$${(`${v}`).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
    }
  },
  methods: {
    cleanFilters() {
      this.$emit('reset-filters', {
        q: '',
        priceRangeDefined: null,
        priceRange: [0, 17000],
        categories: null,
        brands: null,
        ratings: null,
        page: 1,
        perPage: 15,
      })
      this.$emit('filter-action')
    },
    filterAction() {
      this.$emit('filter-action')
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';

</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables.scss';
@import '~@core/scss/base/components/_variables-dark.scss';
.mt-5px{
  margin-top: 5px;
}
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
    ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}

.sidebar-shop{
  z-index: 9999!important;
}

@media (max-width: 991px) {
  .dark-layout{
    .sidebar-shop{
      background: $theme-dark-card-bg;
    }
    .text-primary{
      color: $theme-dark-headings-color!important;
    }
  }
  .sidebar-shop{
    background: #fff;
    .card{
      box-shadow: none;
    }
  }
}
</style>
